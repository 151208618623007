import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectSnippet from "../ProjectSnippet";

const WorkPreview = (props: any) => {
  const { data: projects } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    for (let i = 0; i < projects.length; i++) {
      ScrollTrigger.create({
        trigger: `.scrolltrigger-${i}`,
        // start: "top-=200px center",
        start: "top bottom",
        end: "bottom top",
        toggleClass: "on-screen",
        once: true,
        // markers: true,
      });
    }

    /* ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      markers: true,
    }); */
  }, []);

  const projectList = projects && projects.length > 0 && projects.map( (project: {[key: string]: any}, index: number) => 
    (
      <div key={index} className={`size-${project.content.container_size} scrolltrigger-${index} animate fade-in-grow mb-9 md:mb-0`}>
        <ProjectSnippet project={project} />
      </div>
    )
  );
  
   return (
    <section ref={sectionEl} className="projects-preview pb-15 md:pb-24 lg:pb-32">
      <div className="projects-wrap md:flex flex-wrap justify-end md:gap-y-15 lg:gap-y-45 px-5 md:px-8">
        {projectList}
      </div>

      <div className="mt-15 md:mt-24 lg:mt-45">
        <p className="text-center">
          {/* <NavLink className="text-2xl text-lightgrey underline hover:no-underline" to="/work">More Projects</NavLink> */}
          <Link to="/work" className="text-2xl text-black ml-6 first:ml-0 group relative navlink font-semibold">
            <span>More Projects</span>
            <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
          </Link>
        </p>
      </div>
    </section>
  )
}

export default WorkPreview;
