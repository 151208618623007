const Spacer = (props: any) => {
  const { background_colour: colour, height } = props.data;
  
   return (
    <div
      className="component--spacer"
      style={{
        backgroundColor: 'white',
        height: `${height}px`,
      }}
    >
    </div>
  )
}

export default Spacer;
