import React from "react";

interface IProps {
	className?: string;
	// value?: string;
	// disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IProps> = ({
	className = '',
	// value,
	// disabled,
	onClick = (e: React.MouseEvent<HTMLElement>) => {},
	children,
}) => {
  return (
    <button 
			className={
				'inline-block border-2 border-black text-black text-1xl font-medium px-6 py-2 rounded-2.5xl relative overflow-hidden group hover:text-white transition-all duration-500 ease-in-out ' + className
			} 
		>
			<span className="inline-block w-full h-0 transition-all duration-500 ease-in-out bg-black absolute bottom-0 left-0 z-1 group-hover:h-full" />
			<span className="inline-block relative z-2">{ children }</span>
		</button>
  );
}

export default Button;