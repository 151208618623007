import { useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "../store/GlobalContext";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import NewsletterContact from "./Content/NewsletterContactTile";

const Footer = () => {
  const globalCtx = useContext(GlobalContext);
  const newsletterContact = globalCtx.global.newsletter_contact;
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);



  return (
    <footer ref={sectionEl} className="site-footertext-black py-10 footer">
      <div className="mx-auto w-full max-w-[1202px] px-4 site-footer__container md:px-7.5 animate slide-from-bottom">
        <div className="flex flex-wrap">
          <div className="py-5 animate fade-in-grow w-full md:max-w-1/2 lg:max-w-1/9 flex flex-col">
            <div className="mb-10">
              <h2 className="font-semibold text-3xl leading-none mb-4">
              Can we help you tell your story?
              </h2>
              {/* <p className="font-medium leading-7 text-1.5xl">
                We are, too.
              </p> */}
              <p className="text-1.5xl leading-7 font-semibold">
                <a className="group relative" href="/contact">
                  <span className="inline-block w-0 h-0.5 bg-white absolute -bottom-1 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
                  Get in Touch
                </a>
              </p>
            </div>
            <div className="mt-auto">
              <img src={newsletterContact.logo[0]} alt="Charrette logo" className="w-full max-w-300" />
            </div>
          </div>
          <div className="py-5 animate fade-in-grow w-full md:max-w-1/2 lg:max-w-1/3">
            <NewsletterContact newsletterContact={newsletterContact} />
          </div>
        </div>
        <p className="text-7.5">
          <a className="group relative" href="/privacy-policy">
            <span className="inline-block w-0 h-0.5 bg-white absolute -bottom-1 left-0 transition-all duration-500 ease-in-out group-hover:w-full" />
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer;