import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectSnippet from "../ProjectSnippet";

const AllWork = (props: any) => {
  const { projects } = props.data;
  
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    for (let i = 0; i < projects.length; i++) {
      ScrollTrigger.create({
        trigger: `.scrolltrigger-${i}`,
        // start: "top-=200px center",
        start: "top bottom",
        end: "bottom top",
        toggleClass: "on-screen",
        once: true,
        // markers: true,
      });
    }
    
    /* ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    }); */
  }, [projects]);

  const projectList = projects && projects.length > 0 && projects.map( (project: {[key: string]: any}, index: number) => 
    (
      <div key={index} className={`size-${project.content.container_size} scrolltrigger-${index} animate fade-in-grow mb-9 md:mb-0`}>
        <ProjectSnippet project={project} />
      </div>
    )
  );
  
   return (
    <section ref={sectionEl} className="component--all-work projects-preview pb-32">
      <div className="projects-wrap md:flex flex-wrap justify-end md:gap-y-15 lg:gap-y-45 px-5 md:px-8">
        {projectList}
      </div>

      {/* <div className="mt-45">
        <p className="text-center">
          <NavLink className="text-2xl text-lightgrey underline hover:no-underline" to="/work">More Projects</NavLink>
        </p>
      </div> */}
    </section>
  )
}

export default AllWork;
