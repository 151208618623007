import { useContext } from "react";
import GlobalContext from "../store/GlobalContext";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import MobileNav from "./MobileNav";

const NavBar = () => {
  const globalCtx = useContext(GlobalContext);

  return (
    <div className="py-10">
    <div className="hidden justify-between items-center max-w-wrapper mx-auto  md:py-11 px-2 md:px-5 lg:px-2">
      <Link to="/" className="block mb-5 md:mb-0">
        <img className="mx-auto md:mx-0" width={134} src={globalCtx.global.site_logo_url} alt="" />
      </Link>
      <div className="text-center md:text-left lg:pr-32 md:-mt-4">
        <Navigation />
      </div>
    </div>
    <div className="hidden absolute top-0 left-0 w-full z-50">
      <MobileNav />
    </div>
    </div>
  )
}

export default NavBar;