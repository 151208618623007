import { useState } from "react";
import axios from "axios";
import Button from "../Button";

const apiUrl = process.env.REACT_APP_API_URL;

const MailChimpForm = () => {
  const [userEmail, setUserEmail] = useState('');
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (value: any) => {
    setUserEmail(value);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrors(false);
    
    axios.post(`${apiUrl}/mailchimp`, {
      email: userEmail,
    })
    .then(function (response) {
      // console.log(response.data);
      // if( Math.round(parseFloat(response.data.status) / 100) !== 2 ) {
      if( response.data.status !== 'subscribed' ) {
        setErrors(true);
        setErrorMessage(response.data.title);
        setTimeout(() => {
          setErrors(false);
          setErrorMessage('');
        }, 3000);
      }
      else {
        setUserEmail('');
        setErrors(false);
        setErrorMessage('Thanks for subscribing!');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <div className="flex-1 pr-7">
            <input 
              type="email" 
              placeholder="Email Address" 
              required 
              value={userEmail}
              onChange={(e) => handleChange(e.target.value)}
              className={'w-full h-12.5 bg-transparent placeholder-white outline-0 text-7.5 px-5 text-white border border-white mailchimp-email-field '+(errors ? 'border-2 border-red' : '')}
            />
          </div>
          <Button className="ml-auto text-white border-white">Sign Up</Button>
        </div>
      </form>
      {
       errorMessage && 
        <div className="mt-2">{ errorMessage }</div>
      }
    </div>
  );
}

export default MailChimpForm;
