import { Link } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import MobileNav from "../../../components/MobileNav";

const ProjectHeader = (props: {[key: string]: any}) => {
  const { pageData } = props;
  
  return (
    <section
      className="w-full h-[49.44vw] max-h-[940px] mb-10 md:mb-20 relative mt-15 md:mt-0 wrapper" 
      style={{
        background: `url("${pageData.photos[0][0]}") center center / cover no-repeat`,
      }}
    >
      <span className="overlay block bg-opacity-20 bg-black w-full h-full absolute top-0 left-0" />
    </section>
  );
}

export default ProjectHeader;
