import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TeamTile = (props: any) => {
  const { team_members: teamMembers  } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  const team = teamMembers && teamMembers.length > 0 && teamMembers.map( (teamMember: {[key: string]: any}, index: number) => 
    (
      <div key={index} className="animate fade-in-grow">
        <img src={teamMember.photo[0]} alt={teamMember.name} className="w-full" />
        <span className="text-xl block mt-6">{teamMember.name}</span>
        <span className="text-xl block">{teamMember.role}</span>
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="component--team-tile grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-11 max-w-wrapper px-7 mb-15 md:mb-24 lg:mb-45 mx-auto">
      {team}
    </section>
  )
}

export default TeamTile;
