import NextProject from "./Components/NextProject";
import ProjectInfo from "./Components/ProjectInfo";
import ProjectHeader from "./Components/ProjectHeader";

const Template4 = (props: any) => {
  const { pageData } = props;

  return (
    <>
    {pageData &&
      <>
      <ProjectHeader pageData={pageData} />

      <ProjectInfo pageData={pageData} />

      <section className="pl-5 pr-5 lg:pl-20 lg:pr-0">
        <div className="grid grid-cols-1 md:grid-cols-template4 justify-between items-center pb-15 lg:pb-60">
          <div className="mb-5 md:mb-0"><img className="w-full" src={pageData.photos[1][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[2][0]} alt="" /></div>
        </div>
      </section>

      <section className="pb-15 lg:pb-26">
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[3][0]} alt="" /></div>
        </div>
      </section>

      <section className="pb-15 lg:pb-29">
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[4][0]} alt="" /></div>
        </div>
      </section>

      <section className="pb-15 lg:pb-31">
        <div className="max-w-[1051px] mx-auto flex gap-9">
          <div><img className="w-full" src={pageData.photos[5][0]} alt="" /></div>
          <div className="pt-35"><img className="w-full" src={pageData.photos[6][0]} alt="" /></div>
        </div>
      </section>

      <section className="py-15 lg:py-22.5 px-5 md:px-0" style={{ background: pageData.accent_colour }}>
        <div className="max-w-[1051px] mx-auto flex gap-5 md:gap-17.5">
          <div><img className="w-full" src={pageData.photos[7][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[8][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-45">
        <div className="max-w-[1188px] mx-auto">
          <div><img className="w-full" src={pageData.photos[9][0]} alt="" /></div>
        </div>
      </section>

      <NextProject link={pageData.next_project_link} />
      </>
    }
    </>
  );
}

export default Template4;
