import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useContext } from "react";
// import GlobalContext from "../../store/GlobalContext";
import MailChimpForm from "./MailChimpForm";

const NewsletterContact = (props: any) => {
  // const globalCtx = useContext(GlobalContext);
  // const newsletterContact = globalCtx.global.newsletter_contact;

  const { newsletterContact } = props;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  const social = newsletterContact.contact.social && newsletterContact.contact.social.length > 0 && newsletterContact.contact.social.map( (socialChannel: any, index: any) => 
    (
      <li key={index} className="mr-4 lastt:mr-0 inline-block font-bold text-1xl md:mr-8 md:last:mr-0">
        <a href={ socialChannel.link } target="_blank" rel="noreferrer" className="group relative">
          <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full" />
          { socialChannel.title}
        </a>
      </li>
    )
  );

  return (
    <section ref={sectionEl} className="component--newsletter-contact-tile">
      <div className="pb-0">
        <div>
          <h2 className="font-semibold text-3xl leading-none mb-4 text-white">
            { newsletterContact.newsletter.title }
          </h2>
          <p className="font-medium text-7.5 leading-7 text-white">
            { newsletterContact.newsletter.description }
          </p>
          <div className="mt-7 mb-5">
            <MailChimpForm />
          </div>
        </div>
        <div className="font-medium pt-5 md:pt-15">
          <h2 className="font-semibold text-3xl leading-none mb-4">
            { newsletterContact.contact.title }
          </h2>
          <p className="font-medium text-7.5 leading-7">
            <a href={'mailto:'+newsletterContact.contact.email}>
              { newsletterContact.contact.email }
            </a>
          </p>
          <div dangerouslySetInnerHTML={{ __html: newsletterContact.contact.address }} className="font-medium text-7.5 leading-7" />
          <ul className="list-none mt-5 md:mt-9">
            { social }
          </ul>
        </div>
      </div>
    </section>
  );
}
export default NewsletterContact;
