import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CalloutText = (props: any) => {
  const { title, description, link } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top bottom",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);
  
   return (
    <section ref={sectionEl} className="component--callout-text py-10.5 md:py-17.5 max-w-wrapper mx-auto md:px-13.5 lg:px-2 px-5 animate slide-from-bottom">
      <div className="max-w-800">

        <strong className="text-3xl leading-tight md:text-5.5xl font-bold md:leading-11" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="text-1.5xl leading-10" dangerouslySetInnerHTML={{ __html: description }} />
        {link &&
          <p className="mt-7">
            {/* <NavLink className="text-2xl text-lightgrey underline hover:no-underline" to={link.url}>{link.title}</NavLink> */}
            <NavLink to={link.url} className="text-1.5xl md:text-2xl text-black ml-6 first:ml-0 group relative navlink font-semibold">
              <span>{link.title}</span>
              <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </NavLink>
          </p>
        }
      </div>
    </section>
  )
}

export default CalloutText;
