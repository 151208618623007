import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const FullWidthImage = (props: any) => {
  const { image } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);
  
  return (
    <section ref={sectionEl} className="component--fullwidth-image w-full -mt-0 md:-mt-31">
      <img className="w-full" src={image[0]} alt="" />
    </section>
  )
}

export default FullWidthImage;
