import { useEffect, useState } from "react";
import axios from "axios";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../Button";

const apiUrl = process.env.REACT_APP_API_URL;

const ContactForm = (props: any) => {
  // const [scrollInstance, setScrollInstance] = useState(0);
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    /* const render = () => {
      let counter = scrollInstance;
      counter++;
      setScrollInstance(counter);
    } */

    // const element = ref.current;
    gsap.fromTo(
      document.querySelector(".contact-form__container"),
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: document.querySelector(".contact-form__container"),
          start: "100px bottom",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          // onUpdate: render,
          once: true,
        }
      }
    );
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrors(false);
    setErrorMessage('');

    axios.post(`${apiUrl}/send-email`, {
      email: e.target.fullname.value,
      fullname: e.target.email.value,
      subject: e.target.subject.value,
      message: e.target.message.value.replace(/\n/g, '<br />'),
    })
    .then(function (response) {
      if( response.data.status === 'success' ) {
        setErrors(false);
        setErrorMessage(response.data.message);

        e.target.fullname.value = '';
        e.target.email.value = '';
        e.target.subject.value = '';
        e.target.message.value = '';

        setTimeout(() => {
          setErrors(false);
          setErrorMessage('');
        }, 3000);
      }
      else {
        setErrors(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }

      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

    /* console.log(e.target.fullname.value);
    console.log(e.target.email.value);
    console.log(e.target.subject.value);
    console.log(e.target.message.value); */
  }

  return (
    <div className="contact-form__container animate__fade-down mx-auto max-w-3xl px-4 pb-5 md:px-11 md:pb-20">
      {/* <h2 className="font-semibold text-5.5xl leading-none mb-6 pt-32">
        Contact Us
      </h2> */}
      <div dangerouslySetInnerHTML={{ __html: props.data.content.title }} className="text-3xl py-14 leading-supertight font-normal md:text-4xl md:py-32" />
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          name="fullname" 
          required 
          className="w-full mb-5 md:mb-10 h-12.5 mr-10 text-1.5xl px-5 text-black"
        />
        <input
          type="email"
          placeholder="Email" 
          name="email" 
          required 
          className="w-full mb-5 md:mb-10 h-12.5 mr-10 text-1.5xl px-5 text-black"
        />
        <input
          type="text"
          name="subject" 
          placeholder="Subject" 
          required
          className="w-full mb-5 md:mb-10 h-12.5 mr-10 text-1.5xl px-5 text-black"
        />
        <textarea
          placeholder="Message"
          rows={4}
          name="message" 
          required 
          className="w-full mb-5 md:mb-10 mr-10 text-1.5xl py-2 px-5 text-black" />
        <Button>Submit</Button>
      </form>
      <div className={'text-lg mt-4 md:mt-5 md:text-xl '+(errors ? 'text-red' : '')}>{errorMessage && errorMessage}</div>
    </div>
  );
}

export default ContactForm;
