import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import NextProject from "./Components/NextProject";
import ProjectInfo from "./Components/ProjectInfo";
import ProjectHeader from "./Components/ProjectHeader";

const Template3 = (props: any) => {
  const { pageData } = props;

  const videoEl1 = useRef<HTMLVideoElement>(null);
  const videoEl2 = useRef<HTMLVideoElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ".video-scroll-trigger-1",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
      // onEnter: self => videoEl1?.current?.play(),
      onEnter: self => window.innerWidth > 640 ? videoEl1?.current?.play() : null,
      // onLeave: self => videoEl1?.current?.pause(),
    });
    ScrollTrigger.create({
      trigger: ".video-scroll-trigger-2",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
      // onEnter: self => videoEl2?.current?.play(),
      onEnter: self => window.innerWidth > 640 ? videoEl2?.current?.play() : null,
      // onLeave: self => videoEl2?.current?.pause(),
    });
  }, []);

  return (
    <>
    {pageData &&
      <>

      <ProjectHeader pageData={pageData} />

      <ProjectInfo pageData={pageData} />

      {/* <section>
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[1][0]} alt="" /></div>
        </div>
      </section> */}

      {pageData && 
      pageData.video.mp4_url && 
      pageData.video.webm_url && 
      pageData.video.ogg_url && 
      <section className="video-scroll-trigger-1">
        <div className="w-full">
          <video
            ref={videoEl1}
            width="1920" 
            height="1080" 
            className="w-full h-auto" 
            autoPlay={false} 
            // muted={true} 
            loop={true} 
            controls={true}
          >
            <source src={pageData.video.mp4_url} type="video/mp4" />
            <source src={pageData.video.webm_url}  type="video/webm" />
            <source src={pageData.video.ogg_url}  type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      }

      <section className="py-15 lg:py-64">
        <div className="w-full max-w-[990px] mx-auto">
          <div><img className="w-full" src={pageData.photos[2][0]} alt="" /></div>
        </div>
      </section>
      
      {pageData && 
      pageData.video_2.mp4_url && 
      pageData.video_2.webm_url && 
      pageData.video_2.ogg_url && 
      <section className="pb-15 lg:pb-36 video-scroll-trigger-2">
        <div className="w-full">
          <video
            ref={videoEl2}
            width="1920" 
            height="1080" 
            className="w-full h-auto" 
            autoPlay={false} 
            // muted={true} 
            loop={true} 
            controls={true}
          >
            <source src={pageData.video_2.mp4_url} type="video/mp4" />
            <source src={pageData.video_2.webm_url}  type="video/webm" />
            <source src={pageData.video_2.ogg_url}  type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      }

      <section>
        <div className="mx-auto max-w-[1286px] flex gap-x-5 md:gap-x-7.5">
          <div><img className="w-full" src={pageData.photos[3][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[4][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[5][0]} alt="" /></div>
        </div>
      </section>
      
      <NextProject link={pageData.next_project_link} />
      </>
    }
    </>
  );
}

export default Template3;
