import { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import GlobalContext from "../store/GlobalContext";
import Icon, { HamburgerMenu } from "./Icons";

const MobileNav = (props: any) => {
  const globalCtx = useContext(GlobalContext);
  const menu = globalCtx.global.main_menu;
  const logoUrl = globalCtx.global.site_logo_url;

  const [menuActive, setMenuActive] = useState(false);

  const handleClick = () => {
    menuActive ? setMenuActive(false) : setMenuActive(true);
  }

  const menuItems = menu && menu.length > 0 && menu.map( (menuItem: any, index: any) => 
    (
      <li 
        key={index} 
        className="group"
      >
        <NavLink 
          to={'/'+menuItem.slug} 
          className="block font-sans text-base text-black font-medium py-2.5 px-3 bg-white border-b border-[#e2e8f0] group-last:border-0 hover:border-[#e2e8f0] hover:bg-[#f8fafc] hover:text-blue navlink__mobile"
          onClick={(e) => setMenuActive(false)} 
        >
          {menuItem.title}
        </NavLink>
      </li>
    )
  );

  return (
    <nav className="bg-white">
      <div className="w-full flex items-center justify-between px-5">
        <div>
          <Link
            to="/" 
            className="w-auto h-15 table-cell align-middle mr-4 py-2" 
            onClick={(e) => setMenuActive(false)} 
          >
            <img alt="logo" src={logoUrl} className="max-w-150" />
          </Link>
        </div>
        <button className="mt-2" onClick={handleClick}>
          <Icon type={<HamburgerMenu color="#000000" />} />
        </button>
      </div>
      <ul className={'mb-4 px-5 bg-white pb-2 '+(menuActive ? 'block' : 'hidden')}>
        { menuItems }
      </ul>
    </nav>
  )
}

export default MobileNav;
