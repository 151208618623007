// import React, { useEffect, useState } from 'react';
// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

let slideshowInterval: any;
let i = 0;
let zIndex = 2;

const ImageGridCarousel = (props: any) => {
  // const [gridIndex, setGridIndex] = useState(0);
  /* const items = [
    <div className="item" data-value="1"><img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" /></div>,
    <div className="item" data-value="2"><img src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" /></div>,
    <div className="item" data-value="3"><img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" /></div>,
    <div className="item" data-value="4"><img src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" /></div>,
    <div className="item" data-value="5"><img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" /></div>,
  ]; */

  /* const items = [
    <img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" />,
    <img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" />,
    <img src="http://ksm-api.test/wp-content/uploads/2022/04/samuel-regan-asante-2qoA9NQO1vc-unsplash.jpg" />,
  ]; */

  /* gsap.registerPlugin(ScrollTrigger);
    // const ref = useRef(null);

    useEffect(() => {
      // const element = ref.current;
      gsap.fromTo(
        document.querySelector(".image-grid__grid"),
        {
          // opacity: 0,
          // y: 250
        },
        {
          // opacity: 1,
          // y: 0,
          scrollTrigger: {
            trigger: document.querySelector(".image-grid__grid"),
            start: "top center",
            // start: "center center",
            end: "bottom top",
            // scrub: true
            toggleClass: "on-screen",
            // toggleActions: "play reverse none none",
            markers: true,
          }
        }
      );
    }, []); */

  const playSlideshow = (e: any) => {
    // console.log('e', e);
    // console.log(e.currentTarget.closest('.alice-carousel').querySelector('.alice-carousel__play-btn'));
    // const playBtn = e.currentTarget.closest('.alice-carousel').querySelector('.alice-carousel__play-btn');
    // playBtn.click();
    // console.log(playBtn);
    
    // console.log(e.currentTarget.closest('.image-grid__item'));
    const imageGridWrapper = e.currentTarget.closest('.image-grid__item');
    const slides = imageGridWrapper.querySelectorAll('.image-grid__slide');
   /*  slides.forEach((el: any) => {
      console.log({ el });
    }); */

    slideshowInterval = window.setInterval(function () {
      if (i < slides.length - 1) {
        i++;
        // zIndex++;
        // // console.log('slides[i]:', slides[i]);
        // slides[i].style.zIndex = zIndex;
      }
      else {
        i = 0;
        // zIndex = 2;
        // console.log('slides[i]:', slides[i]);
        // slides[i].style.zIndex = zIndex;
      }

      zIndex++;
      slides[i].style.zIndex = zIndex;
      
    }, 250);

    // let i = gridIndex;
    
    /* let i = 0;

    while (hoverActive === true) {
      console.log('i', i);
      i++;
    } */

    /* do {
      console.log('i', i);
      console.log('hoverActive:', hoverActive);
      i++;
    }
    while (hoverActive); */

    /* setInterval(function () {
      if (hoverActive) {
        console.log('hoverActive:', hoverActive);
      }
    }, 250); */
  }

  const pauseSlideshow = (e: any) => {
    window.clearInterval(slideshowInterval);
  }

  const imageGrid = props.data.blocks && props.data.blocks.length > 0 && props.data.blocks.map( (block: any, index: any) => 
    (
      block.block_type === 'single_image' ? 
      <div key={index} className="image-grid__single-image image-grid__item relative">
        <img className="w-full max-w-full h-auto opacity-0" src={block.content[0]} alt="" />
        <span className="absolute w-full h-full block top-0 left-0" style={{
          background: `url('${block.content[0]}') center no-repeat`,
          backgroundSize: 'cover'
        }} />
      </div>

      : block.block_type === 'image_carousel' ?
      <div key={index} className="image-grid__carousel image-grid__item relative">
        {/* { block.content && block.content.length > 0 && block.content.map( (image: any, index: any) => 
          (
            <img key={index} className="w-full max-w-full h-auto absolute top-0 left-0" src={ image[0] } alt="" />
          )
        )} */}
        {/* <AliceCarousel
            mouseTracking
            // items={block.content}
            // responsive={true}
            animationType="fadeout"
            // autoPlay={true}
            // autoPlayInterval={100}
            animationDuration={100}
            controlsStrategy="alternate"
            infinite={true} 
            disableButtonsControls={true} 
            disableDotsControls={true} 
            autoPlayControls={true} 
        > */}
          { block.content && block.content.length > 0 && block.content.map( (image: any, index: any) => 
            (
              <div 
                key={index} 
                style={{
                  background: `url('${image[0]}') center no-repeat`,
                  backgroundSize: 'cover'
                }} 
                className="w-full h-full absolute top-0 left-0 image-grid__slide" 
                onMouseEnter={(e) => playSlideshow(e)} 
                onMouseOut={(e) => pauseSlideshow(e)} 
              >
                <img className="w-full h-full opacity-0" src={ image[0] } alt="" />
              </div>
            )
          )}
        {/* </AliceCarousel> */}
      </div>

      : block.block_type === 'video' ?
      <div key={index} className="image-grid__video image-grid__item">
        <video
          width="1920" 
          height="1080" 
          poster={block.content.fallback_image[0]} 
          className="w-full h-auto" 
          autoPlay={false}
          muted={true}
          loop={true}
        >
          <source src={block.content.video_urls.mp4_url} type="video/mp4" />
          <source src={block.content.video_urls.webm_url}  type="video/webm" />
          <source src={block.content.video_urls.ogg_url}  type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>

      : null
    )
  );

  return (
    <section className="pb-5 md:pb-32">
      <div className="grid grid-cols-2 image-grid__grid">
        { imageGrid }
      </div>
      <div>
        {/* <AliceCarousel
            mouseTracking
            items={items}
            // responsive={true}
            animationType="fadeout"
            autoPlay={true}
            autoPlayInterval={2000}
            controlsStrategy="alternate"
        /> */}
      </div>
    </section>
  );
}
export default ImageGridCarousel;
