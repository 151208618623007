import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const bannerVideo = require("../../assets/video/banner-video.mp4");

export const Video = () => {
  return (
    <video autoPlay loop muted className="absolute w-full top-0 left-0 h-full" playsInline>
      <source src={bannerVideo} type="video/mp4"
      />
      Sorry, your browser doesn't support videos.
    </video>
  );
};

const HeroSection = (props: any) => {
  const { hero_image: heroImage, logo } = props.data;
  const [sticky, setSticky] = useState(Boolean);

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);


  return (
    <React.Fragment>
      <section 
        ref={sectionEl}
        className="relative w-full h-[70vw] max-h-full mb-0 mt-15 md:mt-0 animate slide-from-bottom" 
        style={{
          // background: `url("${heroImage[0]}") center center / cover no-repeat`,
        }}
      >
        <Video />
      </section>
    </React.Fragment>
  );
}

export default HeroSection;
