import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import firstImage from "../../assets/images/1.png"
import secondImage from "../../assets/images/2.png"
import thirdImage from "../../assets/images/3.png"
import fourthImage from "../../assets/images/4.png"

const ImageCollage = (props: any) => {
  const { images, link } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  /* const collage = images && images.length > 0 && images.map( (image: any[], index: number) => 
    (
      <div key={index}>
        <img src={image[0]} alt="" className="w-full" />
      </div>
    )
  ); */
  
  return (
    <section ref={sectionEl}>
      <div className="component--image-collage grid grid-cols-collage items-center mx-auto max-w-wrapper gap-2 md:gap-10 mb-15 md:mb-24 lg:mb-56">
        <div className="animate fade-in-grow">
          <img src={images[0][0]} className="mb-10 mx-auto" alt="" />
          {/* <img src={firstImage} alt="" className="mx-auto" width={189}/> */}
        </div>
        <div className="animate fade-in-grow">
          <img className="mb-10" src={images[1][0]} alt="" />
          <img src={images[2][0]} alt="" className="mb-10" />
          {/* <img src={secondImage} alt="" width={224} /> */}
        </div>
        <div className="animate fade-in-grow">
          <img src={images[3][0]} alt="" className="mb-10"/>
          {/* <img src={thirdImage} alt="" width={350}/> */}
        </div>
        <div className="animate fade-in-grow">
          <img className="mb-10" src={images[4][0]} alt="" />
          <img src={images[5][0]} alt="" className="mb-10"/>
          {/* <img src={fourthImage} alt="" width={288} height={700}/> */}
        </div>
      </div>
      <div className="text-center text-2xl uppercase pb-24">
        <p>
          <Link to={link.url} className="navlink group relative font-semibold">
            <span>{link.title}</span>
            <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
          </Link>
        </p>
      </div>
    </section>
  )
}

export default ImageCollage;
