import { Link } from "react-router-dom";

const getTags = (tags: any[]) => {
  if(tags){
  return (tags.map( (tag: {[key: string]: any}, index: number) => 
    (
      <li key={index} className="inline-block text-base lg:text-1xl text-lightgrey underline mr-2 last:mr-0 md:mr-9 md:last:mr-0">
        {tag.name}
      </li>
    )
  ))
    }
};

const ProjectSnippet = (props: {[key: string]: any}) => {
  const { project } = props;

  return (
    <>
      <div className="mb-4">
        <Link to={project.path} className="uppercase text-1.5xl leading-tight group relative navlink">
          <img className="w-full mb-11" src={project.content.featured_image_url} alt={project.content.title} />
          <strong>{project.content.title}</strong>
          <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
        </Link>
      </div>
      <p className="text-1.5xl leading-tight max-w-[534px]">{project.content.short_description}</p>
      <ul className="mt-4">
        {getTags(project.content.tags)}
      </ul>
    </>
  )
}

export default ProjectSnippet;
