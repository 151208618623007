import React, { useEffect, useRef, useState, useContext } from "react";
import GlobalContext from "../store/GlobalContext";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Navigation from "./Navigation";
import MobileNav from "./MobileNav";
import { Link } from "react-router-dom";

const Newheader = (props: any) => {
  const [sticky, setSticky] = useState(Boolean);
  const globalCtx = useContext(GlobalContext);
  const newsletterContact = globalCtx.global.newsletter_contact;
  const logo = globalCtx.global.site_logo_url;
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
    window.removeEventListener('scroll', handleScroll);

    }
  }, [])

  const handleScroll=(event:any) =>{
        let scrolled=window.scrollY>=100?true:false
        setSticky(scrolled)
  }

  return (
    <React.Fragment>
      <header className={`fixed top-0 z-9 w-full ease-in duration-300 ${sticky ? 'bg-white md:py-5 shadow-md' : 'md:py-5'}`}>
        <div className="max-w-wrapper mx-auto w-full h-full relative px-0 md:px-13.5 lg:px-2">
          <div className="hidden md:flex items-center justify-end">
            <div className="mr-auto">
              <Link to={'/'}>
                <img src={logo} className={`w-full ease-in duration-300 ${sticky ? 'max-w-120' : 'max-w-200'}`} alt="Charette logo" />
              </Link>
            </div>
            <div className="md:float-right w-full md:w-auto">
              <Navigation />
            </div>
          </div>
          <div className="md:hidden w-full">
            <MobileNav />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Newheader;
