import { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route,useLocation } from 'react-router-dom';

import Page from "../pages/Page";
import Project from "../pages/Project";

const apiUrl = process.env.REACT_APP_API_URL;

const AppRoutes = () => {
  const [routeItems, setRouteItems] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    getRoutes();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getRoutes = () => {
    axios.get(`${apiUrl}/pages/get`)
    .then( response =>  {
      setRouteItems(response.data);
    })
    .catch( error => {
      console.error(error);
    });

    /* const requestPages = axios.get(`${apiUrl}/pages/get`);
    const requestProjects = axios.get(`${apiUrl}/projects/get`);

    axios.all([requestPages, requestProjects])
    .then( responses =>  {        
      let responseOne = responses[0];
      let responseTwo = responses[1];

      setRouteItems(responses[0].data);
    })
    .catch( error => {
      console.error(error);
    }); */
  }

  const routeList = routeItems && routeItems.length > 0 && routeItems.map( (route: any, index: any) => 
    (
      <Route key={index} path={route.path} element={<Page slug={route.slug} />} />
    )
  );

  return (
    <Routes>
      <Route path="/" element={<Page slug="home" />} />
      { routeList }
      <Route path="/projects/:slug" element={<Project />} />
    </Routes>
  );
}

export default AppRoutes;