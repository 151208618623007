import React from "react";

interface IProps {
  color?: string;
}

const IconHamburgerMenu: React.FC<IProps> = ({
  color = '#000000'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.75" height="23" viewBox="0 0 28.75 23">
      <g id="Hamburger_Menu" data-name="Hamburger Menu" transform="translate(0 0.001)">
        <rect id="Rectangle_147" data-name="Rectangle 147" width="28.75" height="3.53" transform="translate(0 -0.001)" fill={color}/>
        <rect id="Rectangle_148" data-name="Rectangle 148" width="28.75" height="3.529" transform="translate(0 9.736)" fill={color}/>
        <rect id="Rectangle_149" data-name="Rectangle 149" width="28.75" height="3.529" transform="translate(0 19.47)" fill={color}/>
      </g>
    </svg>
  );
};

export default IconHamburgerMenu;
