import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TwoColumnText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  return (
    <section ref={sectionEl} className="component--two-column-text animate slide-from-bottom">
      <div className="mx-auto w-full max-w-7xl px-4 pt-10 pb-10 md:flex md:px-7.5 md:pt-40 md:mt-3 md:pb-52">
        <h2 className="font-bold text-4xl pr-6 leading-supertight mb-6 md:basis-2/4 md:text-6.5xl md:mb-0" dangerouslySetInnerHTML={{ __html: props.data.title }} />
        <p className="text-xl leading-superrelaxed md:basis-2/4 md:text-1.5xl">{ props.data.description }</p>
      </div>
    </section>
  );
}
export default TwoColumnText;