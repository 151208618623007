import { useContext } from "react";
import GlobalContext from "../store/GlobalContext";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const globalCtx = useContext(GlobalContext);
  const menu = globalCtx.global.main_menu;

  const navMenu = menu && menu.length > 0 && menu.map( (menuItem: any, index: any) => 
    (
      <NavLink key={index} to={`/${menuItem.slug}`} className="font-sans font-semibold text-lg md:text-1xl text-black ml-6 first:ml-0 group relative navlink">
        <span>{menuItem.title}</span>
        <span className="inline-block w-0 h-px bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
      </NavLink>
    )
  );

  return (
    <nav>
      <ul>
        {navMenu}
      </ul>
    </nav>
  )
}

export default Navigation;