import { Link } from "react-router-dom";

interface IProps {
  link: string;
};

const NextProject: React.FC<IProps> = ({ link }) => {
  return (
    <section>
        <div className="mx-auto max-w-6xl py-15 md:py-24 lg:py-45">
          <p className="text-2xl md:text-4.5xl text-black leading-supersnug text-center">
            <Link to={`/projects${link}`} className="group relative navlink ">
              <span>Next project</span>
              <span className="inline-block w-0 h-1 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </Link>
          </p>
        </div>
      </section>
  );
}

export default NextProject;
