import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import NextProject from "./Components/NextProject";
import ProjectInfo from "./Components/ProjectInfo";
import ProjectHeader from "./Components/ProjectHeader";

const Template1 = (props: any) => {
  const { pageData } = props;

  const videoEl = useRef<HTMLVideoElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ".video-scroll-trigger",
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
      onEnter: self => window.innerWidth > 640 ? videoEl?.current?.play() : null,
      // onLeave: self => videoEl?.current?.pause(),
    });
  }, []);

  return (
    <>
    {pageData &&
      <>
      <ProjectHeader pageData={pageData} />

      <ProjectInfo pageData={pageData} />

      <section>
        <div className="mx-auto max-w-[1295px] px-8 grid grid-cols-2 pb-15 md:pb-28">
          <div><img className="w-full" src={pageData.photos[1][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[2][0]} alt="" /></div>
        </div>
      </section>

      {pageData && 
      pageData.video.mp4_url && 
      pageData.video.webm_url && 
      pageData.video.ogg_url && 
      <section className="video-scroll-trigger">
        <div className="w-full pb-17.5">
          <video
            ref={videoEl}
            width="1920" 
            height="1080" 
            poster={pageData.photos[0][0]} 
            className="w-full h-auto" 
            autoPlay={false} 
            muted={true} 
            controls={true}
          >
            <source src={pageData.video.mp4_url} type="video/mp4" />
            <source src={pageData.video.webm_url}  type="video/webm" />
            <source src={pageData.video.ogg_url}  type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      }

      <section>
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[3][0]} alt="" /></div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-6xl grid grid-cols-project1 gap-10 pt-15 lg:pt-52 pb-15 lg:pb-32">
          <div><img className="w-full" src={pageData.photos[4][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[5][0]} alt="" /></div>
        </div>
      </section>

      <section>
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[6][0]} alt="" /></div>
        </div>
      </section>

      <NextProject link={pageData.next_project_link} />
      </>
    }
    </>
  );
}

export default Template1;
