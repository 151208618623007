import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import GlobalContext from "../../store/GlobalContext";
import Footer from "../../components/Footer";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";

const Project = () => {
  const globalCtx = useContext(GlobalContext);
  const { slug } = useParams();
  const [pageData, setPageData] = useState() as any;
  const siteLogo = globalCtx.global.site_logo_url;

  useEffect(() => {
    axios.get(`${globalCtx.apiUrl}/projects/single?slug=${slug}`)
    .then( response =>  {
      setPageData(response.data);
      window.scrollTo(0, 0);
    })
    .catch( error => {
      console.error(error);
    });
  }, [globalCtx.apiUrl, slug]);

  const getTags = (tags: any[]) => {
    return (tags.map( (tag: {[key: string]: any}, index: number) => 
      (
        <li key={index} className="inline-block text-base md:text-2xl text-lightgrey underline mr-2 last:mr-0 md:mr-9 md:last:mr-0">
          {tag.name}
        </li>
      )
    ))
  };

  return (
    <div className={pageData && `template--${pageData.template}`}>
      <div className="wrapper">
    {pageData &&
      (
        pageData.template === 'template1' ? 
          <Template1 pageData={{
            ...pageData,
            tags: getTags(pageData.tags),
            logo: siteLogo,
          }}
          />
        : pageData.template === 'template2' ? 
          <Template2 pageData={{
            ...pageData,
            tags: getTags(pageData.tags),
            logo: siteLogo,
          }}
          />
        : pageData.template === 'template3' ? 
          <Template3 pageData={{
            ...pageData,
            tags: getTags(pageData.tags),
            logo: siteLogo,
          }}
          />
        : pageData.template === 'template4' ? 
          <Template4 pageData={{
            ...pageData,
            tags: getTags(pageData.tags),
            logo: siteLogo,
          }}
          />
        : null
      )
    }
          </div>
    <Footer />
    </div>
  );
}

export default Project;
