import { Link } from "react-router-dom";

const ProjectInfo = (props: {[key: string]: any}) => {
  const { pageData } = props;
  
  return (
    <section className="mx-auto max-w-[1365px] pt-40 grid grid-cols-1 lg:grid-cols-2 gap-x-5 px-5 md:px-17.5">
      <div className="max-w-2xl">
        <h2 className="text-5xl leading-tight md:text-8xl md:leading-none font-sans">{pageData.short_description}</h2>
        <ul className="my-8 md:my-16">
          {pageData.tags}
        </ul>
        <p className="mb-15 md:mb-40 text-xl md:text-1.5xl leading-10">{pageData.description}</p>
      </div>
      {pageData.template === "template3" ?
        <div className="mb-15 lg:mb-0">
          <img src={pageData.photos[1][0]} alt="" className="w-full mb-4" />
          <p className="text-sm md:text-base leading-loose uppercase">
            {pageData.photos[1][4]}
            {/* <Link to="/work" className="group relative navlink">
              <span>Back to all</span>
              <span className="inline-block w-0 h-1 bg-white absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </Link>
            <span className="inline-block mx-5">/</span>
            <Link to={`/projects${pageData.next_project_link}`} className="group relative navlink">
              <span>Next project</span>
              <span className="inline-block w-0 h-1 bg-white absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </Link> */}
          </p>
        </div>
        :
        <div className="hidden lg:block">
          <p className="text-right text-2xl leading-loose">
            <Link to="/work" className="group relative navlink">
              <span>Back to all</span>
              <span className="inline-block w-0 h-1 bg-white absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </Link>
            <span className="inline-block mx-5">/</span>
            <Link to={`/projects${pageData.next_project_link}`} className="group relative navlink font-semibold">
              <span>Next project</span>
              <span className="inline-block w-0 h-0.5 bg-black absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full navlink__underline" />
            </Link>
          </p>
        </div>
      }
    </section>
  );
}

export default ProjectInfo;
