// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import logo from './logo.svg';
// import './App.css';

import Newheader from "./components/NewHeader";
import AppRoutes from "./routes/Routes";

const App = () => {
  return (
    <div className="App bg-white font-sans text-black overflow-x-hidden">
      <Newheader />
      <AppRoutes />
    </div>
  );
}

export default App;
