import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TextWithImage = (props: any) => {
  const { title, description, image } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  return (
    <section ref={sectionEl} className="component--text-with-image md:flex md:gap-x-15 lg:gap-x-56 items-center mx-auto max-w-wrapper pb-15 md:pb-24 ;g:pb-56 md:px-13.5 lg:px-2 px-5">
      <div className="px-5 pb-8 md:px-0 md:pb-0 animate slide-from-left">
        <strong className="text-3xl leading-tight md:text-5.5xl font-bold md:leading-11" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="text-1.5xl leading-10" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="animate slide-from-right">
        <img src={image[0]} alt="" />
      </div>
    </section>
  )
}

export default TextWithImage;
