import NextProject from "./Components/NextProject";
import ProjectInfo from "./Components/ProjectInfo";

const Template2 = (props: any) => {
  const { pageData } = props;

  return (
    <>
    {pageData &&
      <>
      <ProjectInfo pageData={pageData} />

      {pageData && pageData.photos && pageData.photos.length > 11 &&
      <>
      <section>
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[0][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-60">
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[1][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-54">
        <div className="grid grid-cols-2 gap-x-15 md:gap-x-32">
          <div className="pl-5 md:pl-26"><img className="w-full" src={pageData.photos[2][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[3][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-49">
        <div className="max-w-[914px] mx-auto grid grid-cols-2 gap-x-5 lg:grid-cols-template2 justify-between items-center">
          <div><img className="w-full" src={pageData.photos[4][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[5][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-37.5">
        <div className="max-w-lg mx-auto">
          <div><img className="w-full" src={pageData.photos[6][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-29">
        <div className="max-w-[1117px] mx-auto grid grid-cols-2 gap-x-5 lg:grid-cols-template2-2 justify-between items-start">
          <div className="pt-31"><img className="w-full" src={pageData.photos[7][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[8][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-29">
        <div className="w-full">
          <div><img className="w-full" src={pageData.photos[9][0]} alt="" /></div>
        </div>
      </section>

      <section className="pt-15 lg:pt-56">
        <div className="flex gap-6 max-w-[993px] mx-auto">
          <div><img className="w-full" src={pageData.photos[10][0]} alt="" /></div>
          <div><img className="w-full" src={pageData.photos[11][0]} alt="" /></div>
        </div>
      </section>
      </>
      }

      <NextProject link={pageData.next_project_link} />
      </>
    }
    </>
  );
}

export default Template2;
