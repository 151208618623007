import { useEffect, useState, useContext } from "react";
import axios from "axios";

import GlobalContext from "../store/GlobalContext";
// import Header from "../components/Header";
import Footer from "../components/Footer";

import AllWork from "../components/Content/AllWork";
import CalloutText from "../components/Content/CalloutText";
import ContactForm from "../components/Content/ContactForm";
import FullWidthImage from "../components/Content/FullWidthImage";
import HeaderNav from "../components/Content/HeaderNav";
import HeroSection from "../components/Content/HeroSection";
import ImageCollage from "../components/Content/ImageCollage";
import ImageGridCarousel from "../components/Content/ImageGridCarousel";
import NewsletterContactTile from "../components/Content/NewsletterContactTile";
import Spacer from "../components/Content/Spacer";
import TeamTile from "../components/Content/TeamTile";
import TextWithImage from "../components/Content/TextWithImage";
import TwoColumnCta from "../components/Content/TwoColumnCta";
import TwoColumnList from "../components/Content/TwoColumnList";
import TwoColumnText from "../components/Content/TwoColumnText";
import WorkPreview from "../components/Content/WorkPreview";

const Page = (props: any) => {
  const globalCtx = useContext(GlobalContext);
  const [pageData, setPageData] = useState() as any;

  useEffect(() => {
    axios.get(`${globalCtx.apiUrl}/pages/single?slug=${props.slug}`)
    .then( response =>  {
      setPageData(response.data);
      window.scrollTo(0, 0);
    })
    .catch( error => {
      console.error(error);
    });
  }, [globalCtx.apiUrl, props.slug]);

  const pageContent = pageData && pageData.page_content && pageData.page_content.length > 0 && pageData.page_content.map( (content: any, index: any) => 
    (
      content.content_type === 'all_work' ?
        <AllWork key={content.component_id} data={content} />
      : content.content_type === 'callout_text' ?
        <CalloutText key={content.component_id} data={content} />
      : content.content_type === 'contact_form' ?
        <ContactForm key={content.component_id} data={content} />
      : content.content_type === 'fullwidth_image' ?
        <FullWidthImage key={content.component_id} data={content} />
      : content.content_type === 'header_nav' ?
        <HeaderNav key={content.component_id} data={content} />
      : content.content_type === 'hero_section' ?
        <HeroSection key={content.component_id} data={content} />
      : content.content_type === 'image_collage' ?
        <ImageCollage key={content.component_id} data={content} />
      : content.content_type === 'image_grid_carousel' ?
        <ImageGridCarousel key={content.component_id} data={content} />
      : content.content_type === 'newsletter_and_contact_tile' ?
        <NewsletterContactTile key={content.component_id} />
      : content.content_type === 'spacer' ?
        <Spacer key={content.component_id} data={content} />
      : content.content_type === 'team_tile' ?
        <TeamTile key={content.component_id} data={content} />
      : content.content_type === 'text_with_image' ?
        <TextWithImage key={content.component_id} data={content} />
      : content.content_type === 'two_column_list' ?
        <TwoColumnList key={content.component_id} data={content} />
		  : content.content_type === 'two_column_text' ?
        <TwoColumnText key={content.component_id} data={content} />
		  : content.content_type === 'two_column_with_cta' ?
        <TwoColumnCta key={content.component_id} data={content} />
		  : content.content_type === 'work_preview' ?
        <WorkPreview key={content.component_id} data={content} />
      : null
    )
  );

  return (
    <div className="site-wrapper">
      <div className="wrapper">
      {/* <Header /> */}
      { pageContent }
      </div>
      {
        pageContent && 
        <Footer />
      }
    </div>
  );
}

export default Page;
