import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

const TwoColumnCta = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  return (
    <section ref={sectionEl} className="component--two-column-cta">
      <div className="mx-auto w-full max-w-7xl px-4 pt-5 pb-5 relative two-col-cta__container animate slide-from-bottom md:grid md:grid-cols-2 md:px-7.5 md:pt-29 md:pb-32">
        <div>
          { props.data.column_1.title && 
            <h2 className="font-semibold text-4xl leading-none mb-5 md:mb-10.5 md:text-5.5xl">{ props.data.column_1.title }</h2>
          }
          <div dangerouslySetInnerHTML={{  __html: props.data.column_1.content }} className="column-1 mb-10 md:mb-0" />
        </div>
        <div>
          { props.data.column_2.title && 
            <h2 className="font-semibold text-5.5xl leading-none mb-12">{ props.data.column_2.title }</h2>
          }
          <div dangerouslySetInnerHTML={{  __html: props.data.column_2.content }} className="column-2" />
          <div className="pt-2 md:pt-5">
            <Link 
              to={'/'+props.data.cta.link_url.post_name} 
              className="text-xl md:text-3xl font-medium group relative"
            >
              <span className="inline-block w-0 h-1 bg-white absolute -bottom-0.5 left-0 transition-all duration-500 ease-in-out group-hover:w-full" />
              { props.data.cta.link_text }
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TwoColumnCta;
