import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TwoColumnList = (props: any) => {
  const { title, list } = props.data;

  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      // start: "top-=200px center",
      start: "top bottom",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });
  }, []);

  return (
    <section ref={sectionEl} className="component--two-column-list md:flex max-w-wrapper mx-auto px-5 mlg:px-2">
      <h2 className="text-5.5xl w-100 pr-5 lg:pt-16 animate slide-from-bottom">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: list.column_1 }} className="text-1.5xl leading-10 mb-5 md:mb-20 pt-5 md:pt-0 lg:pt-36 pr-5 w-87.5 animate slide-from-left" />
      <div dangerouslySetInnerHTML={{ __html: list.column_2 }} className="text-1.5xl leading-10 pt-5 md:pt-0 lg:pt-36 w-87.5 animate slide-from-right" />
    </section>
  )
}

export default TwoColumnList;
