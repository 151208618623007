// import React from "react";
import NavBar from "../NavBar";

const HeaderNav = (props: any) => {
  return (
    <NavBar />
  )
}

export default HeaderNav;
